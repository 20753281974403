<template>
  <tr style="padding: 0px;">
    <td style="text-align: center;padding: 0px;"> {{ detail.idx }} </td>

    <td style="padding: 0px;width: 150px;">
      <select class="form-control" v-if="detail" v-model="detail.work_order" @change="loadItems(detail.work_order)" >
        <option v-for="order in orders" v-bind:value="order.code">
          {{ order.ord_no }}
        </option>
      </select>
    </td>

    <td style="padding: 0px;">
      <select class="form-control" v-if="detail" v-model="detail.item.code" @change="rowValueEqualizer(2)" >
        <option v-for="item in items" v-bind:value="item.itm.code">
           {{item.itm.code}} - {{ item.itm.name }}
        </option>
      </select>
    </td>


    <td style="padding: 0px;">
      <input type="number" class="form-control text-right" min="0"  step="any" placeholder="0" v-if="detail" v-model="detail.qty" @change="rowValueEqualizer(4)"/>
    </td>

    <td style="padding: 0px;">
      <input type="number" class="form-control text-right" min="0"  step="any" placeholder="0" v-if="detail" v-model="detail.weight" @change="rowValueEqualizer(5)"/>
    </td>

    <td class="text-center" style="padding: 0px;">
      <button name="btnRemoveRow"   type="button" class="btn border-warning text-warning-600 btn-flat btn-icon btn-rounded legitRipple btnRemoveRow" @click="removeRow()" tabindex="-1" ><i class="icon-eraser"></i></button>
    </td>
  </tr>
</template>

<script>
  import moment from 'moment-timezone'
  import {userService} from '@/store/auth-header.js'
  export default {
    name: 'ProductionDetailRow',
    props: {
      is_credit: {
        type:String,
        default: () => 'Y'
      },
      myrow: {
        type: Object,
        default: () => JSON.parse('{"idx":0,"branch":{"id":"","code":1,"name":"","street":"","city":"","pincode":"","state_code":0,"contact_person":"","telephone":"","email":""},"order_no":1,"work_order":1,"item":{"id":"","code":0,"nature":0,"name":"","p_name":"","article":{"id":"","code":0,"type":0,"nature":0,"name":"","p_name":"","hsn":"","gstrate":0,"itcrate":0,"unit":{"id":"","code":0,"name":"","digits":0,"sys_obj":false},"subunit":{"id":"","code":0,"name":"","digits":0,"sys_obj":false},"conv_fact":0,"has_bom":false,"sys_obj":false},"category":{"id":"","code":0,"name":"","p_name":""},"brand":{"id":"","code":0,"name":"","print_name":""},"unit":{"id":"","code":0,"name":"","digits":0,"sys_obj":false},"rate":0,"min_qty":0,"max_qty":0,"sysobj":false},"unit":{"id":"","code":1,"name":"","digits":0,"sys_obj":false},"rate":0,"qty":0,"qty_txt":"","weight":0,"taxable_amt":0,"gst_rate":0,"tax_amt":0,"igst_amt":0,"sgst_amt":0,"cgst_amt":0,"cess_amt":0,"net_amt":0}')
      },
      myjobworker: {
        type: Number,
        default: () => 0
      },
      myindex: {
        type: Number,
        default: () => 0
      },
      myprocess: {
        type: Number,
        default: () => 0
      }
    },
    beforeMount () {

      this.credit_r_debit = this.is_credit;
      this.detail = this.myrow;
      this.jobworker = this.myjobworker;
      this.process = this.myprocess;

      //this.loadOrders(this.myprocess, this.myjobworker);
      //this.loadItems(this.myrow.work_order);
      this.loadAll();

      this.detail = this.myrow;
      this.detail.qty = Math.abs(this.detail.qty);
      this.detail.weight = Math.abs(this.detail.weight);
    },
    data () {
      return {
        process: 0,
        credit_r_debit: '-',
        index: 0,
        jobworker : 0,
        detail : JSON.parse('{"idx":0,"branch":{"id":"","code":1,"name":"","street":"","city":"","pincode":"","state_code":0,"contact_person":"","telephone":"","email":""},"order_no":1,"work_order":1,"item":{"id":"","code":0,"nature":0,"name":"","p_name":"","article":{"id":"","code":0,"type":0,"nature":0,"name":"","p_name":"","hsn":"","gstrate":0,"itcrate":0,"unit":{"id":"","code":0,"name":"","digits":0,"sys_obj":false},"subunit":{"id":"","code":0,"name":"","digits":0,"sys_obj":false},"conv_fact":0,"has_bom":false,"sys_obj":false},"category":{"id":"","code":0,"name":"","p_name":""},"brand":{"id":"","code":0,"name":"","print_name":""},"unit":{"id":"","code":0,"name":"","digits":0,"sys_obj":false},"rate":0,"min_qty":0,"max_qty":0,"sysobj":false},"unit":{"id":"","code":1,"name":"","digits":0,"sys_obj":false},"rate":0,"qty":0,"qty_txt":"","weight":0,"taxable_amt":0,"gst_rate":0,"tax_amt":0,"igst_amt":0,"sgst_amt":0,"cgst_amt":0,"cess_amt":0,"net_amt":0}'),
        items: [],
        orders: [],
        mapItems: new Map(),
      }
    },
    methods:{

      loadAll(){
        this.loadOrders(this.process, this.jobworker);
        this.loadItems(this.detail.work_order);
      },
      removeRow(){
        this.$emit('onRemoveRow', (this.$data.detail.idx * -1) );
      },
      rowValueEqualizer(idx) {
        try {

          let detail = this.$data.detail;
          switch (idx) {
            case 1: //Work Order

              break;
            case 2: //Item

              detail.weight = this.$data.mapItems.get(detail.item.code).wgt.toFixed(3);
              detail.gst_rate = this.$data.mapItems.get(detail.item.code).itmgrp.gst;
              detail.item.group.gstrate = this.$data.mapItems.get(detail.item.code).itmgrp.gst;


              break;
            case 3: //Rate


              detail.rate = parseFloat(detail.rate);

              this.$data.detail.taxable_amt = detail.qty * detail.rate;
              this.$data.detail.tax_amt = (detail.taxable_amt * (detail.gst_rate / 100)).toFixed(2);
              this.$data.detail.net_amt = (detail.taxable_amt + parseFloat(detail.tax_amt)).toFixed(2);
              this.$data.detail.tax_amt = parseFloat(detail.tax_amt).toFixed(2);
              this.$data.detail.tax_amt = detail.tax_amt.toFixed(2);
              this.$data.detail.taxable_amt = (detail.qty * detail.rate).toFixed(2);
              break;
            case 4:  //Qty


              detail.qty = parseFloat(detail.qty);

              this.$data.detail.weight = (this.$data.detail.qty * this.$data.mapItems.get(this.$data.detail.item.code).wgt).toFixed(3);

              this.$data.detail.taxable_amt = detail.qty * detail.rate;
              this.$data.detail.tax_amt = (detail.taxable_amt * (detail.gst_rate / 100)).toFixed(2);
              this.$data.detail.net_amt = (detail.taxable_amt + parseFloat(detail.tax_amt));
              this.$data.detail.tax_amt = parseFloat(detail.tax_amt).toFixed(2);
              this.$data.detail.tax_amt = detail.tax_amt.toFixed(2);
              this.$data.detail.taxable_amt = (detail.qty * detail.rate).toFixed(2);
              break;
            case 5: //Weight
              break;
            case 6: //Taxable Amount

              this.$data.detail.taxable_amt = parseFloat(detail.taxable_amt);
              this.$data.detail.rate = (detail.taxable_amt / detail.qty).toFixed(2);
              this.$data.detail.tax_amt = (detail.taxable_amt * (detail.gst_rate / 100)).toFixed(2);
              this.$data.detail.net_amt = (detail.taxable_amt + parseFloat(detail.tax_amt)).toFixed(2);
              break;
          }
        }catch (e) {

        }
      },
      async loadOrders(processcode,jobworkercode){
        let self = this;
        const requestOptions = {
          mode:'cors',
          headers: userService.authHeader()
        };

        self.$data.orders = [];
        await fetch(`${process.env.VUE_APP_ROOT_API}v1/tms/workorder/${processcode}/${jobworkercode}/`,requestOptions).then(userService.handleResponse).then(function (resp) {

          if (resp.accepted){
            if(resp.data != null){
              self.$data.orders = resp.data;
            }
          }else{
            swal (  {title:"Oops" ,  text: resp.message,  type: "error"});
          }
        }).catch(function (err) {
          swal (  {title:"Oops" ,  text: err.toString(),  type: "error"});
        });

      },
      async loadItems(ordercode){
        let self = this;
        const requestOptions = {
          mode:'cors',
          headers: userService.authHeader()
        };

        self.$data.items = [];
        self.$data.mapItems = new Map();

        let myurl = `${process.env.VUE_APP_ROOT_API}v1/tms/workorder/output/${ordercode}/`;
        if(self.$data.credit_r_debit == 'N'){
          myurl = `${process.env.VUE_APP_ROOT_API}v1/tms/workorder/input/${ordercode}/`;
        }

        await fetch(myurl,requestOptions).then(userService.handleResponse).then(function (resp) {
          if (resp.accepted){
            self.$data.items = resp.data;

            resp.data.forEach(function (i) {
              self.$data.mapItems.set(i.itm.code, i.itm);
            });

          }else{
            swal (  {title:"Oops" ,  text: resp.message,  type: "error"});
          }
        }).catch(function (err) {
          swal (  {title:"Oops" ,  text: err.toString(),  type: "error"});
        });
      },
    }
  }
</script>

<style scoped>

</style>
